"use client";

import React, { useEffect } from "react";
import Image from "next/image";
import NewPrimaryButton from "../../Atoms/Buttons/NewPrimaryButton";
import { Switch_404 } from "../../../public/Theme";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { GoogleTagManagerEvents } from "../../../common";
import {
    GO_TO_HOMEPAGE,
    NOT_FOUND_DESCRIPTION,
    STYLE_NOT_FOUND,
    TEXT_404,
} from "./constants";
import { sendGTMEvent } from "@next/third-parties/google";

const NotFound404 = () => {
    useEffect(() => {
        sendGTMEvent({
            event: GoogleTagManagerEvents.pageNotFound,
            menu_name: "NotFound",
            place: "404Page",
        });
    }, []);

    return (
        <div className="py-10 w-full flex flex-col gap-y-4 sm:gap-y-6 justify-center items-start">
            <div className="flex flex-col gap-2 sm:gap-y-3 justify-center items-center sm:w-1/2 mx-4 sm:mx-auto">
                <div>
                    <TypographyMontserrat
                        align={"center"}
                        size={"100px"}
                        text={TEXT_404}
                        fontWeight={"bold"}
                    />
                    <TypographyMontserrat
                        align={"center"}
                        size={"40px"}
                        text={STYLE_NOT_FOUND}
                        fontWeight={"semibold"}
                    />
                </div>
                <TypographyMontserrat
                    align={"center"}
                    size={"2xl"}
                    text={NOT_FOUND_DESCRIPTION}
                    fontWeight={"medium"}
                />
            </div>
            <div className="flex flex-col gap-y-6 justify-center w-fit mx-auto">
                <NewPrimaryButton href={"/"} text={GO_TO_HOMEPAGE} />
                <Image
                    height={1000}
                    width={1000}
                    alt="switch-404"
                    src={Switch_404}
                />
            </div>
        </div>
    );
};

export default NotFound404;
