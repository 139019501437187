"use client";
import React from "react";
import { PROCESSING_GIF_ALT } from "./constants";
import { PRIMARY_BUTTON_CLASS, PRIMARY_BUTTON_DISABLED_CLASS } from "./styles";
import { LoadingWhiteSpinner } from "../../../../public/Theme";
import Image from "next/image";
import Link from "next/link";
import { SIGN_UP_ENDPOINT } from "../../../../public/Constants/EnumConstants";
import GTMLink from "../../GTMLink";



const NewPrimaryButton = ({
    text,
    onClick,
    processing,
    width,
    href,
    className,
    style,
    type,
    disabled,
    section,
    buttonId,
    image,
    target,
    imageSize,
    googleEventName,
    place,
    menuName,
}) => {
    const buttonClass = disabled
        ? PRIMARY_BUTTON_DISABLED_CLASS
        : PRIMARY_BUTTON_CLASS;
    return href ? (
        <GTMLink
            href={href}
            id={buttonId}
            data-cy={buttonId}
            passHref
            prefetch
            className={width + PRIMARY_BUTTON_CLASS + className}
            style={style}
            onClick={onClick}
            event={googleEventName}
            place={place}
            menuName={menuName}
            text={text}
            name={text}
            target={target}
        >
            {processing ? (
                <Image
                    src={LoadingWhiteSpinner}
                    width={31}
                    height={30}
                    alt={PROCESSING_GIF_ALT}
                />
            ) : (
                text
            )}
            {image && (
                <Image
                    src={image}
                    width={imageSize ? imageSize : 14}
                    height={imageSize ? imageSize : 14}
                    alt={PROCESSING_GIF_ALT}
                />
            )}
        </GTMLink>
    ) : (
        <button
            type={type}
            className={width + buttonClass + className}
            style={style}
            onClick={onClick}
            disabled={disabled}
            id={buttonId}
            data-cy={buttonId}
        >
            {processing ? (
                <Image
                    src={LoadingWhiteSpinner}
                    width={31}
                    height={30}
                    alt={PROCESSING_GIF_ALT}
                />
            ) : (
                text
            )}
            {image && (
                <Image
                    src={image}
                    width={imageSize ? imageSize : 14}
                    height={imageSize ? imageSize : 14}
                    alt={PROCESSING_GIF_ALT}
                />
            )}
        </button>
    );
};

export default NewPrimaryButton;
